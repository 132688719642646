import Vue from 'vue'
import App from './App.vue'
import moment from 'moment'
import { store } from '@/store';
import VModal from 'vue-js-modal'
import router from '@/router';
import babelPolyfill from 'babel-polyfill';

Vue.use(VModal)


Vue.config.productionTip = false
new Vue({
  router,
  babelPolyfill,
  store,
  moment,
  render: h => h(App)
}).$mount('#app')    
