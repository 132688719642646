<template>
    <div class="register-page">
        <RegisterForm></RegisterForm>
    </div>
</template>

<script>
import RegisterForm from '@/components/auth/RegisterForm.vue';
export default {
    name:"Register",
    components: { RegisterForm }
}
</script>

<style scoped>
.register-page{
    background-image: url('https://t4.ftcdn.net/jpg/03/41/83/83/360_F_341838327_s6a1cXcFzdCXydsGXeUZnUlbKycoI8y1.jpg');
    background-repeat: no-repeat; 
    background-size: cover;
}
</style>