<template>
    <div :class="className">
        <div class="box">
            <a :href="'/course/'+course.slug">
                <div class="img-box">
                    <img class="thumbnail-course" :src="course.thumbnail_url" :alt="course.description" :height="imageHeight">
                </div>
                <div class="mt-1 mr-2 view-count float-right d-flex">
                    <i class="fa fa-xs fa-graduation-cap mr-1 mt-1"></i>
                    <span class="text-center">{{ course.joined_count}}</span>
                </div>
                <div class="detail-box">
                    <div class="title-author pt-2">
                        <h5>
                            {{ course.title}}
                        </h5>
                        <p class="pt-2">
                            Giáo viên: {{ course.author }}
                        </p>
                    </div>
                    <div class="price_box d-flex justify-content-between">
                        <h6 class="price_heading">
                            <span class="unit">{{ course.unit }}</span> <p><b>{{ course.price | formatCurrency }}</b></p>
                        </h6>
                       
                        <a class="btn-register-course" :href="'/course/'+course.slug">
                            Đăng ký
                        </a>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name:"CourseCard",
    props:['course','className'],
    mounted(){
        this.originImageWidth = document.querySelector("img").naturalWidth;
        if(window.innerWidth >= 993 ){
            this.imageHeight = (1/5)*this.originImageWidth
        }
        else if(window.innerWidth > 767 && window.innerWidth < 993){
            this.imageHeight = (1.3/5)*this.originImageWidth
        }
        else if(window.innerWidth <= 767 && window.innerWidth > 410){
            this.imageHeight = (1.9/5)*this.originImageWidth
        }
        else{
            this.imageHeight = (1.6/5)*this.originImageWidth
        }
    },
    data(){
        return{
            imageHeight:'auto',
            originImageWidth: '100%',
        }
    }
}
</script>

<style scoped>

.img-box{
    padding:0 !important;
    align-items: flex-start !important;
    height:auto !important;
}
.thumbnail-course{
    border-radius: 3% 3% 0 0;
    width:100%;
}
.box{
    border-radius:3%;
    padding:0 !important;
    margin-top: 30px !important;
    border: 0.5px solid lightgrey;
    height:auto;
}
.box a{
    color: black;
}
.detail-box{
    padding:15px;
    font-size: 0.8rem;
}
.title-author > p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 0.8rem;
    font-weight: 600;
}
.title-author > h5{
    height:2.7rem; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.price_heading > span{
    color: darkgrey !important;
    font-size:0.5rem;
}
.btn-register-course{
    background-color: #E7EEB5;
    border-radius: 0.25rem;
    color: #082E49 !important;
    padding: 7px 9px;
    margin: 2px 2px;
    align-self: center;
    cursor: pointer;
}
.view-count{
    font-size: 0.8rem;
}
</style>