<template>
    <!-- Single Blog Post -->
    <div :class="className">
        <div class="single-blog-post mb-50">
            <!-- Thumbnail -->
            <div class="post-thumbnail">
                <a :href="'/blog/'+blog.slug" class="post-title"><img :src="blog.thumbnail_url" :alt="blog.title" class="thumbnail-image" :height="imageHeight"></a>
            </div>
            <!-- Content -->
            <div class="post-content p-3">
                <p class="post-date">{{ blog.created_at ? blog.created_at : today | formatFullDateTime }}</p>
                <a :href="'/blog/'+blog.slug" class="post-title">
                    <h5 class="blog-title">{{blog.title}}</h5>
                </a>
                <p class="post-excerpt pt-2">{{ blog.brief }}</p>
                <div class="text-center">
                    <router-link class="btn btn-warning" style="border:none; background-color: #E7EEB5; color: #082E49" :to="'/blog/'+blog.slug">Xem thêm</router-link>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(Date.now());
let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(Date.now());
let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(Date.now());
var today = `${ye}-${mo}-${da}`;
export default {
    name:"BlogCard",
    props: ['blog','className'],
    mounted(){
        this.originImageWidth = document.querySelector("img").naturalWidth;
        if(window.innerWidth >= 993 ){
            this.imageHeight = (1/5)*this.originImageWidth
        }
        else if(window.innerWidth > 767 && window.innerWidth < 993){
            this.imageHeight = (1/5)*this.originImageWidth
        }
        else if(window.innerWidth <= 767 && window.innerWidth > 576){
            this.imageHeight = (1/5)*this.originImageWidth
        }
        else if(window.innerWidth <= 576 && window.innerWidth > 450){
            this.imageHeight = (2/5)*this.originImageWidth
        }
        else{
            this.imageHeight = (1.4/5)*this.originImageWidth
        }
    },
    data(){
        return{
            today: today,
            imageHeight:'auto',
            originImageWidth: '100%',
        }
    }
}
</script>

<style scoped>
.single-blog-post{
    border: 1px solid lightgrey;
    border-radius: 1rem;
    margin:1rem 0;
}
.post-thumbnail a > img{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    width: 100%;
}
.post-content .post-excerpt{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 0.9rem;
    height: 4.5rem;
}
.post-content .post-date{
    font-size:0.7rem;
    overflow: hidden;
    height:1.6rem;
}
.post-content{
    height:16rem;
}
.post-content .blog-title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: black !important;
    height: 3rem;
}
</style>