<template>
    <div class="course-detail mt-2 container mb-5">
        <div class="course-section row">
            <div class="col-lg-5">
                <div class="course-thumbnail">
                    <img :src="course.thumbnail_url" width="100%" :alt="course.description" />
                </div>
                <div class="course-short-info mt-2 d-flex ">
                    <div class="w-50 border p-2 text-center rounded">
                        <span>Khuyến mãi: <strong>{{ course.discount+'%' }} </strong></span> 
                        <span><s><i>{{ (course.price/(100-(course.discount)))*100 | formatCurrency }} </i></s> </span>                      
                        <p>Giá: <strong>{{ course.price | formatCurrency}}</strong> {{ course.unit}}</p>
                    </div>
                    <div class="ml-3 w-50 enroll-button">
                        <button class="btn btn-warning w-100" @click="show">Đăng ký</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="course-description">
                    <p>{{ course.description }}</p>
                </div>
            </div>
        </div>

        <modal name="register-course" :adaptive="true">
            <div class="container m-2">
                <div class="title-modal d-flex justify-content-between">
                    <div class="">
                        <h4>Đăng ký sản phẩm</h4>
                    </div>
                    <div class="float-right">
                        <button class="btn" @click="hide">&#10006;</button>
                    </div>
                </div>
                <div class="form-group">
                    <label for="courseName">{{ course.title }}</label>
                </div>
                 <div class="form-group">
                    <input type="text" class="form-control" id="phone" v-model="phone" placeholder="Phone">
                </div>
                <div class="form-group">
                    <textarea class="form-control" id="note" v-model="note" placeholder="Note"></textarea>
                </div>

                <div class="text-danger">{{ error ? error : ''}}</div>
                <div class="form-group text-center">
                    <button type="submit" class="btn btn-primary btn-register-course" @click="registerCourse">Gửi</button>
                </div>
            </div>
        </modal>

        <div class="warning" v-if="warning != null">
            <span class="text-warning">{{ warning }}</span>
        </div>
        <div class="videos-in-course mt-5" v-if="videos.length > 0">
            <h4>Video trong sản phẩm</h4>
            <div class="video" v-for="video in videos" :key="video.id">
                <h5>{{ video.title }}</h5>
                <video controls>
                    <source :src="video.file_path" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
        
        <div class="course-comment mt-3">
            <Comments v-if="course.id != null" :postId="course.id" postType='COURSE'></Comments>
        </div>
        
        <div class="courses-related mt-2">
            <h5>Courses liên quan</h5>
            <swiper class="swiper" :options="swiperOption">
                <swiper-slide class="col-sm-6 col-md-6 col-lg-3" v-for="item in courses" :key="item.id"><CourseCard :course="item"></CourseCard></swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
        </div>
    </div>    
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Comments from '@/components/comment/CommentArea.vue';
import CourseCard from '@/components/course/CourseCard.vue';

import { mapState } from 'vuex'
import {courseService, orderService } from '@/services';
var screenWidth = screen.width;
export default {
    name:"Course",
    components:{Comments, CourseCard, Swiper,SwiperSlide},
    data(){
        return{
            course: new Object(),
            resizable: true,
            courses:[],
            note: null,
            error:null,
            warning:null,
            videos:[],
            phone:null,
            swiperOption: {
                slidesPerGroup: 4,
                slidesPerView: screenWidth <= 576 ? 2 : 4,
                spaceBetween: 30,
                centeredSlides:false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
        }
    },
    computed:{
        ...mapState('account', ['status'])
    },
    mounted(){
        courseService.getCourseDetail(this.$route.params.slug).then(res=>{
            this.course = res;
            courseService.getCoursesRelated(this.course.category_id).then(res=>{
                this.courses = res;
            });
            if(this.status.loggedIn){
                orderService.checkCurrentUserOrder(this.course.id, 'COURSE').then(res=>{
                    if(res == null){
                        return;
                    }
                    else{
                        if(res.is_paid == 1){
                            let params = {
                                'productId': this.course.id,
                                'productType': 'COURSE',
                                'sort': 1,
                            };
                            courseService.getVideosInCourse(params).then(res=>{
                                this.videos = res;
                            })
                        }
                        else{
                            this.warning = "You haven't paid the course yet, please complete payment!"
                        }
                    }

                })
            }
        })
        
    },
    methods:{
        show () {
            if(!this.status.loggedIn){
                this.$router.push('/login');
            }
            this.$modal.show('register-course');
        },
        hide () {
            this.$modal.hide('register-course');
        },
        registerCourse(){
            let params = {
                'courseId': this.course.id,
                'courseTitle': this.course.title,
                'amount' : (this.course.price/(100-(this.course.discount)))*100,
                'note'   : this.note,
                'phone' : this.phone
            }
            courseService.registerCourse(params).then((res,err)=>{
                if(res.id){
                    this.$modal.hide('register-course'); 
                }
                else{
                    this.error = err;
                }
            })
        }
    }
}
</script>

<style scoped>
@media (max-width: 576px){
    .swiper-wrapper > div{
        margin-right: 0 !important;
        width: 13rem!important;
    }
}
.course-description{
    border: 1px solid lightgrey;
    border-radius: 1rem;
    padding:1rem;
    min-height: 25rem;
}
.enroll-button > button{
    min-height:7rem;
}
.course-short-info > div:first-child{
    border-color: lightgrey;
}
.video{
    margin-top:2rem;
    border-bottom:1px solid lightgrey;
    text-align: center;
    padding:1rem;
}
.video > video{
    max-width: 100%;
    min-height: auto;
    margin:0 auto;
}

</style>
