<template>
    <div class="blogs-page">
        <Header></Header>
        <Blogs></Blogs>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/layouts/Header.vue';
import Footer from '@/components/layouts/Footer.vue';
import Blogs from '@/components/blog/Blogs.vue';

document.title = "Thần thoại quanh ta - Blogs";
export default {
    name:"BlogsPage",
    components:{Header, Footer, Blogs},
    data(){
        return{}
    }
}
</script>

<style scoped>
.blogs-page{
  background-color: #fcfaf7;
}
</style>