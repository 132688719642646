<template>
    <div class="books">
        <section class="furniture_section layout_padding">
            <div class="container">
            <div class="heading_container">
                <p class="title-home">
                    Câu chuyện đề xuất
                </p>
                <div class="description d-inline-block w-100" >
                    <span class="float-right">
                        <a class="see-more-link" href="/stories">Xem thêm <i class="ml-1 fa fa-chevron-right"></i></a>
                    </span>
                </div>
            </div>
            <div class="books-slide">
                <div class="row">
                    <BookCard v-for="book in books" :key="book.id" :book="book" :className="'col-md-6 col-lg-3'"></BookCard>
                </div>
            </div>
            </div>
        </section>
    </div>
</template>

<script>
import {bookService} from '@/services';
import BookCard from '@/components/book/BookCard.vue';
export default {
    name:"Book",
    components:{BookCard},
    data(){
        return{
            books:[]
        }
    },
    mounted(){
        bookService.getBooksRecommended().then(res=>{
            this.books = res.data
        })
    },
}
</script>

<style scoped>
.see-more-link{
    text-decoration: underline;
    color: black;
}
.layout_padding{
    padding: 30px 0 !important;
}
</style>