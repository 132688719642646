import config from '../config/api.js';
export const blogService = {
    getBlogDetailBySlug,
    getBlogsInCategory,
    searchBlogByName,
    getBlogsRecommended
};

function getBlogDetailBySlug(slug) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/blog/detail/${slug}`, requestOptions)
        .then(handleResponse);
}

function getBlogsInCategory(categoryId, page = 1, blogView=6) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/blog/getBlogsInCategory/${categoryId}?page=${page}&blog_view=${blogView}`, requestOptions)
        .then(handleResponse);
}

function getBlogsRecommended(page = 1, blogView = 6) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/blog/getBlogsRecommended?page=${page}&blog_view=${blogView}`, requestOptions)
        .then(handleResponse);
}

function searchBlogByName(searchString, blogView = 6){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }
    return fetch(`${config.apiUrl}/blog/searchBlogs/${searchString}?blog_view=${blogView}`, requestOptions)
        .then(handleResponse);
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // tự động logout nếu response 401 được trả về từ api
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data.data;
    });
}