<template>
    <div class="header">
        <header class="header_section long_section px-0">
      <nav class="navbar navbar-expand-lg custom_nav-container ">
        <a class="navbar-brand" href="/">
          <img src="@/assets/images/logo/logo.png" class="logo-header">
        </a>
        <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class=""> </span>
        </button>

        <div class="navbar-collapse collapse" id="navbarSupportedContent" style="">
          <div class="d-flex mx-auto flex-column flex-lg-row align-items-center">
            <ul class="navbar-nav">
              <li class="nav-item active">
                <a class="nav-link" href="/">Trang chủ<span class="sr-only">(current)</span></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/courses">Sản phẩm</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/stories">Kho truyện</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/blogs">Blogs</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/about-us">Về chúng tôi</a>
              </li>
            </ul>
          </div>
          <div class="quote_btn-container">
            <span v-if="status.loggedIn">
              Xin chào, <strong>{{ user.fname}}</strong> &nbsp; &nbsp; &nbsp;
            </span>
            <a v-if="status.loggedIn" href="javascript:void(0)" @click="Logout()">
              <i class="fa fa-sign-out" aria-hidden="true"></i>
            </a>
            <a v-else href="/login">
              <span>
                Đăng nhập
              </span>
              <i class="fa fa-user" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </nav>
    </header>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name:"Header",
    data(){
      return{
        currentUser:[]
      }
    },
    computed: {
      ...mapState(
        'account',['status','user']
      )
    },
    mounted(){
      if(this.status.loggedIn){
        this.getUser();
      }
    },
    methods: {
      ...mapActions(
        'account',['logout','getUserInfo']
      ),
      Logout () {
        this.logout();
      },
      getUser(){
        this.getUserInfo();
      }
    }
}
</script>

<style scoped>
.logo-header{
  height:3em;
}
.header_section{
  background-color: #fcfaf7!important;
}
.quote_btn-container{
  width: 10rem;
  margin:auto;
}
</style>