<template>
    <div class="courses mb-4 mt-4">
        <section class="courses-section">
            <div class="container">
                <div class="course-filter">
                    <h2 class="title">
                        Sản phẩm 
                    </h2>
                    <div class="description mt-3" >
                        <div class="filter-title row">
                            <div class="col-sm-12 col-lg-6 d-flex mt-1">
                                <button @click="search" class="btn btn-light border border-secondary search-course">Tìm kiếm</button>
                                <input class="ml-1 form-control w-75" type="text" v-model="searchString" placeholder="VD: Cô bé quàng khăn đỏ"/>
                            </div>
                            <div class="col-lg-6 col-sm-12 d-flex filter-content mt-1">
                                <span class=""><button class="btn btn-filter" @click="submitFilter()">Lọc</button></span>
                                <Dropdown   
                                    :options="authors"
                                    v-on:selected="getAuthorSelected"
                                    v-on:filter="getAuthorValue"
                                    placeholder="Authors"
                                    >
                                </Dropdown>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="courses-slide mt-3">
                    <div class="row">
                        <div class="col-lg-9 col-sm-12">
                            <div class="row">
                                <i v-if="emptyMessage != null" class="empty-message"><b>{{ emptyMessage}}</b></i>
                                <CourseCard v-for="course in totalCourses.data" :key="course.id" :course="course" :className="'col-xs-12 col-sm-12 col-md-6 col-lg-6'"></CourseCard>
                            </div>
                            <!-- Pager -->
                            <ul class="nikki-pager list-unstyled d-flex justify-content-center" v-if="totalCourses.total > 0">
                                <li class="p-2" v-for="p in totalCourses.last_page" :key="p">
                                    <button :class="[{active: p == page}, 'btn', 'btn-light', 'border-secondary']" @click="loadMoreCourses(p)">{{ p }}</button>
                                </li>
                            </ul>
                        </div>
                        
                        <div class="col-lg-3 course-categories mt-4">
                            <div class="pl-2 pb-2"><strong >Danh mục </strong></div>
                            <ul>
                                <li v-for="cate in categories" :key="cate.id"><a href="#" @click="submitCategory(cate)">{{cate.name}}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { courseService, categoryService } from '@/services';
import CourseCard from '@/components/course/CourseCard.vue';
import Dropdown from 'vue-simple-search-dropdown';

export default {
    name:"Courses",
    components:{CourseCard,Dropdown},
    data(){
        return{
            page: 1,
            totalCourses:[],
            authors:[],
            selectedAuthor:'',
            categories:[],
            selectedCategory:'',
            sliderValue:0,
            emptyMessage:null,
            searchString:null,
        }
    },
    mounted(){
        categoryService.getCategoryByType('COURSE').then(res=>{
            this.categories = res;
        })
        courseService.getCourseAuthors().then(res=>{
            this.authors = res;
        })
  
        courseService.getCoursesMostJoined().then(res=>{
            this.totalCourses = res;
            if(this.totalCourses.total == 0){
                this.emptyMessage = 'No courses found!'
            }
        })
    },
    computed: {
        ...mapState('account',['status'])
    },
    methods:{
        loadMoreCourses(){
            this.page += 1;
            courseService.getCoursesMostJoined(this.page).then(res=>{
                // Concat more data to totalCourses
                this.totalCourses.data = this.totalCourses.data.concat(res.data);
            })
        },
        getAuthorValue(e){
            return e;
        },
        getAuthorSelected(author){
            this.selectedAuthor = author.name;
        },
        getCategorySelected(cate){
            this.selectedCategory = cate.id;
        },
        getCategoryValue(e){
            return e;
        },
        submitFilter(){
            let author = (this.selectedAuthor != '') ? {author:this.selectedAuthor} : null;
            let category = (this.selectedCategory != '') ? {category:this.selectedCategory} : null;
            let price = this.sliderValue > 0 ? {price: this.sliderValue}: null;
            let filterContent = {...author, ...category, ...price};
            courseService.filterCourses(filterContent).then(res=>{
                this.totalCourses.data = res;
                this.totalCourses.total = 0;
                if(res.length == 0){
                    this.emptyMessage = 'No courses found!'
                }
            });
        },
        submitCategory(cate){
            let filterContent = {category:cate.id};
            courseService.filterCourses(filterContent).then(res=>{
                this.totalCourses.data = res;
                this.totalCourses.total = 0;
                if(res.length == 0){
                    this.emptyMessage = 'No courses found!'
                }
                else{
                    this.emptyMessage = null;
                }
            });
        },
        search(){
            courseService.searchCourses(this.searchString).then(res=>{
                this.totalCourses.data = res;
                this.totalCourses.total = 0;
                if(res.length == 0){
                    this.emptyMessage = `No course found for '${this.searchString}'`;
                }
            },err=>{console.log(err)})
        }
    }
}
</script>

<style scoped>
.layout_padding{
  padding: 30px 0!important;
}
.btn-loadmore{
    text-decoration: underline;
    color:black !important;
    margin: 1em auto;
}
.dropdown{
    margin:0px 12px;
}
.dropdown input{
    height: 2.4rem;
}
.author-filter, .category-filter{
    align-items:center;
}
.filter-title > span{
    align-self: center;
}
.courses-slide > .box{
    width: 14rem;
}
.btn-filter{
    background-color: whitesmoke !important;
    color: black;
    border: 1px solid gray
}
.empty-message{
    margin:0 auto;
}
.course-categories ul > li > a{
    color: black !important;
}
</style>