<template>
    <div class="comment-card">
        <div class="row">
            <div class="user-avatar col-lg-1 d-flex align-items-center">
                <div class="circle border shadow">
                    <span class="initials">{{ comment.user_name | formatName }}</span>   
                </div>
            </div>
            <div class="user-info col-lg-3 col-sm-3">
                <div class="user-name">
                    {{ comment.user_name }}
                </div>
                <div class="time mt-3">
                    {{ comment.created_at | formatDateTime }}
                </div>
            </div>
            <div class="comment-content col-lg-7 col-sm-8">
                <div class="box-content">
                    {{ comment.content}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Comment",
    props: ['comment'],
    data(){
        return{
            
        }
    },
}
</script>

<style scoped>
@media (max-width: 576px) { 
    .user-avatar{
        display: none !important;
    }
    .time{
        font-size:0.7rem;
    }
 }
.comment-card{
    padding:0.5rem;
    margin: 0.5rem;
}
.comment-content{
    border: 1px solid lightgrey;
    border-radius: 0.5rem;
    margin-right: 1rem;
}
.circle {
  border-radius: 50%;
  height: 2.7rem;
  text-align: center;
  width: 2.7rem;
  background-color: #ccc;
  position: relative;
}

.initials {
  font-size: calc(2.5rem / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(2.5rem / 5); /* 25% of parent */
}

</style>