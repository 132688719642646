import Vue from 'vue';
import moment from 'moment';
import Vue2Filters from 'vue2-filters';
import numeral from 'numeral';
moment.locale('vn');

Vue.use(Vue2Filters);

Vue.filter("formatCurrency", function (value) {
    return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});
Vue.filter("formatDateTime", function(value){
    return moment(value).format("DD-MM-YY HH:mm");
})

Vue.filter("formatFullDateTime", function(value){
    return moment(value).format("DD-MM-YYYY HH:mm:ss");
})

Vue.filter("formatName", function(name){
    let fullname = name.split(' ')
    let initials = fullname.shift().charAt(0) + fullname.pop().charAt(0);
    return initials.toUpperCase();
});