<template>
    <div class="">
        <section class="about_section w-100">
            <img width="100%" src="@/assets/images/DSCF5694.jpeg" alt="">
            <div class="about_layer">
                <h5>Thần thoại quanh ta</h5>
                <p class="about-us-title">Về chúng tôi</p>
            </div>
            
        </section>

        <section class="thu-ngo mt-5">
            <div class="thu-ngo-content w-75 m-auto">
                <h4 class="text-left">Chúng tôi là ai?</h4>
                <p class="mt-4">
                    Trước hết, chúng mình đều là những người rất yêu trẻ con.
                    <br>
                    Sau đó, một số trong chúng mình trải nghiệm hành trình làm bố mẹ, làm giáo viên và khám phá ra rằng những câu chuyện và việc kể chuyện cho trẻ em có mang trong mình một sức mạnh giáo dục và chữa lành thật kì diệu. Từ đó chúng mình đã cùng nhau thực hiện dự án "Thần Thoại Quanh Ta" cho các gia đình và những em bé nhỏ. 
                </p>
                <br>
                
                <h4 class="text-left">Sứ mệnh của chúng tôi:</h4>
                <p class="mt-4">
                    Dự án "Thần Thoại Quanh Ta" là nơi lưu giữ cho con hy vọng và niềm tin vào một thế giới thần tiên, nơi những điều tử tế và tốt đẹp luôn luôn ở cùng con; cùng con vun đắp một tâm hồn trong sáng, một trái tim bao dung và một sức mạnh tinh thần để xây dựng một thế giới thực tại tốt đẹp hơn. 
                </p>
                <br>
                <h4 class="text-left">Câu chuyện truyền cảm hứng cho chúng tôi:</h4>
                <p class="mt-4">
                    “Mẹ! Con muốn chơi game cả ngày”
                    <br>
                    “Mẹ! Con sợ môn văn” 
                    <br>
                    “Mẹ! Con không thích đọc sách!"
                    <br>
                    Khoảng cách ba mẹ và con cái ngày càng xa, vai trò của smartphone, Ipad, game, Tiktok,... trở nên quan trọng bậc nhất đối với các con,... "Làm thế nào để giúp con?" Có một công cụ, một giải pháp có thể tiếp cận mỗi ngày, vô cùng tiết kiệm và lại rất thú vị: các câu chuyện Thần thoại và cổ tích. 
                    <br>
                    Trong thế giới vô vàn màu sắc của những câu chuyện, bé tìm thấy mình trong đó, đầy sức mạnh từ tình yêu thương và niềm tin vào điều tử tế. Còn ba mẹ, cũng tìm thấy một cách dễ nhất để kết nối với con, với tuổi thơ của chính mình, chỉ cần gác lại hết tất cả, ngồi xuống cùng con và những câu chuyện, cùng nghe, cùng đọc, cùng cảm nhận, cùng khám phá thế giới muôn màu ngoài kia và thế giới kỳ diệu trong tâm hồn con. 
                </p>
                <br>
                <h4 class="text-left">Hoạt động của dự án Thần Thoại Quanh Ta:</h4>
                <p class="mt-4">
                    Những buổi "Cùng nghe kể chuyện" dành cho ba mẹ và các con trong độ tuổi 4 đến 12 tuổi
                    <br>
                    Những quyển sách và truyện đầy màu sắc do Thần Thoại Quanh Ta lựa chọn, hoặc chính do Thần Thoại Quanh Ta biên soạn và xuất bản.
                    <br>
                    Kho câu chuyện liên tục được bổ sung, ngay trên website này.
                    <br>
                    Kho audio được các cô giáo, các bậc cha mẹ và các con cùng đọc, cũng liên tục được cập nhật và ngay trên website này.
                    <br>
                    Nếu bạn quan tâm, hãy liên hệ với chúng tôi theo thông tin dưới đây, hoặc mua sách truyện, đọc kho truyện và nghe audio ngay tại web này nhé.
                </p>

            </div>
        </section>

        <section class="contact mt-5 container">
            <div class="contact-title container">
                <h3 class="text-center">Thông tin liên hệ</h3>
                <!-- <p class="description m-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis.</p> -->
            </div>
            <div class="mt-3 d-flex justify-content-around contact-content">
                <div class=" phone text-center">
                    <p class="phone-icon">
                        <a href="tel:0962666854">
                            <i class="fa fa-phone-square fa-4x"></i>
                        </a>
                    </p>
                    <p>
                        Zalo/Phone : <b>0962666854/0933966998</b>
                    </p>
                </div>
                <div class="text-center email">
                     <p class="phone-icon">
                        <a href="mailto:thanthoaiquanta@gmail.com">
                            <i class="fa fa-envelope-o fa-4x"></i>
                        </a>
                    </p>
                    <p>
                        Email : <b>thanthoaiquanhta@gmail.com</b>
                    </p>
                    
                </div>
            </div>
            <div class="address mt-3">
                <div class="social-media mb-3">
                    <h5 class="text-center">Theo dõi chúng tôi trên</h5>
                    <br>
                    <div class="row m-auto" style="width: 15%;">
                        <div class="col-lg-4">
                            <a href="https://facebook/examplefanpage">
                                <i class="fa fa-facebook-official fa-2x"></i>
                            </a>
                        </div>
                        <div class="col-lg-4">
                            <a href="tel:0962666854">
                                <i class="fa fa-phone-square fa-2x"></i>
                            </a>
                        </div>
                        <div class="col-lg-4">
                            <a href="mailto:thanthoaiquanta@gmail.com">
                                <i class="fa fa-envelope-o fa-2x"></i>
                            </a>
                        </div>
                    </div>
                </div>
                
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d501726.4614770392!2d106.41434661392795!3d10.754665781988544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529292e8d3dd1%3A0xf15f5aad773c112b!2sHo%20Chi%20Minh%20City%2C%20Vietnam!5e0!3m2!1sen!2s!4v1649859297718!5m2!1sen!2s" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name:"AboutUs",
    props:[''],
    data(){
        return{

        }
    },
    methods:{
    }
}
</script>

<style scoped>
.circle {
  border-radius: 50%;
  height: 10.0rem;
  text-align: center;
  width: 10.0rem;
  background-color: #ccc;
  position: relative;
  margin: 0 auto;
}

.initials {
  margin-top:1rem;
  font-size: calc(2.5rem / 2); /* 50% of parent */
  line-height: 1;
  text-align: center;
}
.member{
    margin: 1rem;
    padding:1rem;
}
.member-quote{
    margin-top:1rem;
    border: 1px solid lightgrey;
    box-shadow: 2px 2px 2px lightgrey;
    padding: 1rem;
    border-radius: 1rem;
}
.about_section{
    width: 100vw;
    background: black;
    overflow: hidden;
    position: relative;
}
/* .image-bg{
    background-color: rgba(0, 0, 0, 0.44);
} */
.about_section img{
    object-fit: cover;
    opacity: 0.6;
}
.about_layer{
    position: absolute;
    top:50%;
    width:100%;
    text-align: center;
}
.about_layer h5, .about-us-title{
    color: white !important;
}
.about_layer .about-us-title{
    font-size: 2.25rem;
}
.overview{
    margin:-7% auto;
    padding:2rem;
    background-color: rgb(232, 184, 84);
    border-radius: 2rem;
    color: white ;
}
.overview h2{
    color: #1c6775;
    font-weight: 600;
}
.description-overview{
    height:30vh;
    position: relative;
}

.thu-ngo .thu-ngo-content{
    color: white;
    background-color: rgb(232, 184, 84);
    padding:2rem;
    border-radius: 2rem;
}
.thu-ngo .thu-ngo-content > p{
    line-height: 2rem;
}
.contact-content .phone, .email{
    background-color:none;
    padding:1rem;
    margin:1rem;
}
.phone-icon > a{
    color:black;
}
.social-media div a{
    color:black;
}
</style>