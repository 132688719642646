<template>
    <div class="course-detail">
        <Header></Header>
        <Course></Course>
        <Footer></Footer>
    </div>
</template>

<script>

import Header from '@/components/layouts/Header.vue';
import Footer from '@/components/layouts/Footer.vue';
import Course from '@/components/course/Course.vue';

document.title = "Thần thoại quanh ta - Sản phẩm";
export default {
    name:"CourseDetail",
    components:{Header, Footer, Course},
    mounted(){
        
    },
    data(){
        return{
            course:null
        }
    }
}
</script>

<style scoped>
.course-detail{
    background-color: #fcfaf7;
}
</style>