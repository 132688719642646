<template>
    <div class="blogs-component mt-4">
        <!-- ##### Blog Content Area Start ##### -->
        <section class="blog-content-area section-padding-0-100">
            <div class="container">
                <div class="row">
                    <!-- Blog Posts Area -->
                    <div class="col-lg-9">
                        <div class="blog-posts-area">
                            <div class="row">
                                <!-- Section Heading -->
                                <div class="col-12">
                                    <div class="section-heading">
                                        <h2>{{ blogs.total > 0 ? blogs.data[0].category_name : ''}}</h2>
                                    </div>
                                </div>
                                <i v-if="emptyMessage != null" class="empty-message"><b>{{ emptyMessage}}</b></i>
                                <BlogCard v-for="blog in blogs.data" :key="blog.id" :blog="blog" :className="'col-lg-4 col-md-4 col-sm-6'"></BlogCard>
                            </div>
                        </div>

                        <!-- Pager -->
                        <ul class="nikki-pager list-unstyled d-flex justify-content-center" v-if="blogs.total > 0">
                            <li class="p-2" v-for="p in blogs.last_page" :key="p">
                                <button :class="[{active: p == page}, 'btn', 'btn-light', 'border-secondary']" @click="loadMoreBlogs(p)">{{ p }}</button>
                            </li>
                        </ul>
                    </div>

                    <!-- Blog Sidebar Area -->
                    <div class="col-lg-3">
                    <div class="ml-3 post-sidebar-area">

                        <!-- ##### Single Widget Area ##### -->
                        <div class="ml-3 single-widget-area mb-50 d-flex">
                            <form class="search-form row" onsubmit="event.preventDefault()">
                                <input v-model="searchString" type="search" name="search" class="form-control col-lg-9 col-md-9 col-sm-9" placeholder="Search...">
                                <button class="ml-1 col-sm-2 col-lg-2 col-md-2 btn btn-light btn-rounded border-secondary" @click="searchBlog(searchString)"><i class="fa fa-search"></i></button>
                            </form>
                        </div>

                        <!-- ##### Single Widget Area ##### -->
                        <div class="single-widget-area mb-30 mt-4">
                            <!-- Title -->
                            <div class="widget-title">
                                <div class="pl-2 pb-2"><strong >Danh mục </strong></div>
                            </div>
                            <ul class="nikki-catagories">
                                <li class="" v-for="cate in categories" :key="cate.id">
                                    <a :href="'/blogs?category_id='+cate.id">
                                        <span>
                                            {{ cate.name}}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {blogService} from '@/services';
import {categoryService} from '@/services';
import BlogCard from '@/components/blog/BlogCard.vue';

export default {
    name:"Blogs",
    components:{BlogCard},
    data(){
        return{
            categoryId:null,
            blogs:[],
            categories:[],
            page:1,
            searchString:null,
            emptyMessage:null,
        }
    },
    mounted(){
        categoryService.getCategoryByType('BLOG').then(res=>{
            this.categories = res;
            if (this.$route.query.category_id && !isNaN(this.$route.query.category_id)) {
                blogService.getBlogsInCategory(this.$route.query.category_id, 1, 6).then(res => {
                    this.blogs = res;
                }, err => console.log(err))
            }
            else{
                blogService.getBlogsRecommended(1,6).then(res => {
                    this.blogs = res;
                }, err => console.log(err))
            }
        },err=>console.log(err))
    },
    methods:{
        loadMoreBlogs(index){
            this.page = index++;
            if (this.$route.query.category_id && !isNaN(this.$route.query.category_id)) {
                blogService.getBlogsInCategory(this.$route.query.category_id, this.page, 6).then(res => {
                    this.blogs = res;
                }, err => console.log(err))
            }else{
                blogService.getBlogsRecommended(this.page, 6).then(res => {
                    this.blogs = res;
                }, err => console.log(err))
            }
        },
        searchBlog(blogName){
            blogService.searchBlogByName(blogName).then(res=>{
                this.blogs = res;
                if(res.total == 0){
                    this.emptyMessage = "Không có dữ liệu";
                }
            },err=>console.log(err))
        }
    }
}
</script>

<style scoped>

.nikki-catagories > li{
    padding:0.3rem;
}
.nikki-catagories > li > a{
    color:black !important;
}
.empty-message{
    margin:0 auto;
}
</style>

