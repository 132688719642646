<template>
    <div class="blog-detail-page">
        <Header></Header>
        <BlogDetail></BlogDetail>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/layouts/Header.vue';
import Footer from '@/components/layouts/Footer.vue';
import BlogDetail from '@/components/blog/BlogDetail.vue';
document.title = "Thần thoại quanh ta - Blogs";

export default {
    name:"BlogDetailPage",
    components: {Header, Footer, BlogDetail},
    data(){
        return{}
    },
    mounted(){
        
    }
}
</script>

<style scoped>
.blog-detail-page{
  background-color: #fcfaf7;
}
</style>