<template>
  <div class="home-page">
    <Header></Header>
    <Banner></Banner>
    <Course></Course>
    <Book></Book>
    <CommentsHome></CommentsHome>
    <Footer></Footer>
  </div>
</template>

<script>
import Banner from '@/components/home/Banner.vue';
import Course from '@/components/home/Course.vue';
import Book from '@/components/home/Book.vue';
import Header from '@/components/layouts/Header.vue';
import Footer from '@/components/layouts/Footer.vue';
import CommentsHome from '@/components/home/Comment.vue';

document.title = "Thần thoại quanh ta";
export default {
  components: { Banner, Course, Book, Header, Footer, CommentsHome},
  name: 'Home',
  data: () => ({
    //
  }),
};
</script>

<style scoped>
.home-page{
  background-color: #fcfaf7;
}
</style>