import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import Courses from '@/views/Courses.vue';
import Books from '@/views/Books.vue';
import CourseDetail from '@/views/CourseDetail.vue';
import BookDetail from '@/views/BookDetail.vue';
import AboutUsView from '@/views/AboutUs.vue';
import BlogsPage from '@/views/Blogs.vue';
import BlogDetailPage from '@/views/BlogDetail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  }, 
  { 
    path: '/login', 
    name: 'Login',
    component: Login
  },
  { 
    path: '/register', 
    name: 'Register',
    component: Register
  },
  {
    path: '/course/:slug',
    name: 'CourseDetail',
    component: CourseDetail
  },
  {
    path: '/story/:slug',
    name: 'BookDetail',
    component: BookDetail
  },
  { 
    path: '/courses', 
    name: 'Courses',
    component: Courses
  },
  { 
    path: '/stories', 
    name: 'Stories',
    component: Books
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: BlogsPage,
  },
  {
    path: '/blog/:slug',
    name: 'BlogDetail',
    component: BlogDetailPage,
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: AboutUsView
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   // chuyển đến trang login nếu chưa được login
//   const publicPages = ['/login', '/register'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');
//   if (authRequired && !loggedIn) {
//     return next('/login');
//   }

//   if(!authRequired && loggedIn) {
//     return next(from.path);
//   }
//   next();
// });

export default router;
