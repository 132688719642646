import { authHeader} from '../helpers';
import config from '../config/api.js';

export const bookService = {
    getBooksRecommended,
    getBooksRead,
    getBookAuthors,
    filterBooks,
    getBookDetail,
    getBooksRelated,
    orderBook,
    getBookFiles,
    searchBooks,
    getBooksInCategory
};

function getBooksRecommended(page = 1, bookNum = 4) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({bookNum:bookNum})
    };

    return fetch(`${config.apiUrl}/book/booksRecommended?page=${page}`, requestOptions)
        .then(handleResponse);
}

function getBooksRead(page = 1){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({bookNum:6})
    };

    return fetch(`${config.apiUrl}/book/booksRead?page=${page}`, requestOptions)
        .then(handleResponse);
}

function getBookAuthors(){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/book/getAuthors`, requestOptions)
        .then(handleResponse);
}

function filterBooks(filterContent){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const queryStr = Object.keys(filterContent)
    .map(key => `${key}=${filterContent[key]}`)
    .join('&');

    return fetch(`${config.apiUrl}/book/filterBooks?${queryStr}`, requestOptions)
        .then(handleResponse);
}

function getBooksRelated(categoryId, page=1){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/book/booksRelated/${categoryId}?page=${page}`, requestOptions)
        .then(handleResponse);
}


function getBooksInCategory(categoryId, page=1, bookView = 6){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/book/booksInCategory/${categoryId}?page=${page}&book_view=${bookView}`, requestOptions)
        .then(handleResponse);
}

function getBookDetail(slug){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/book/detail/${slug}`, requestOptions)
        .then(handleResponse);
}

function orderBook(params){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };

    return fetch(`${config.apiUrl}/book/orderBook`, requestOptions)
        .then(handleResponse);
}

function getBookFiles(params){
    const requestOptions ={
        method: 'POST',
        headers:  { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
    };

    return fetch(`${config.apiUrl}/document/getDocumentsByProduct`, requestOptions)
    .then(handleResponse);
}

function searchBooks(searchString){
    const requestOptions ={
        method: 'GET',
        headers:  { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/book/search/${searchString}`, requestOptions)
    .then(handleResponse);
}

function logout() {
    // xoá user từ local storage để log out
    localStorage.removeItem('user');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // tự động logout nếu response 401 được trả về từ api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data.data;
    });
}