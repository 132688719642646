<template>
    <div class="books-area mb-4 mt-4">
        <section class="books-section">
            <div class="container">
                <div class="book-filter">
                    <h2 class="title">
                        Kho truyện
                    </h2>
                    <div class="description mt-3" >
                        <div class="filter-title row">
                            <div class="col-lg-6 col-sm-12 d-flex mt-1 ">
                                <button @click="search" class="btn btn-light border border-secondary search-book">Tìm kiếm</button>
                                <input class="ml-1 form-control w-75" type="text" v-model="searchString" placeholder="VD: Cô bé quàng khăn đỏ"/>
                            </div>
                            <div class="col-lg-6 col-sm-12 d-flex mt-1 author-filter">
                                <span class=""><button class="btn btn-filter" @click="submitFilter()">Lọc</button></span>
                                <Dropdown   
                                    :options="authors"
                                    v-on:selected="getAuthorSelected"
                                    v-on:filter="getAuthorValue"
                                    placeholder="Authors">
                                </Dropdown>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="books-slide mt-3">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="row">
                                <i v-if="emptyMessage != null" class="empty-message"><b>{{ emptyMessage}}</b></i>
                                <BookCard v-for="book in totalBooks.data" :key="book.id" :book="book" :className="'col-md-6 col-lg-4'"></BookCard>
                            </div>
                          
                            <!-- Pager -->
                            <ul class="nikki-pager list-unstyled d-flex justify-content-center" v-if="totalBooks.total > 0">
                                <li class="p-2" v-for="p in totalBooks.last_page" :key="p">
                                    <button :class="[{active: p == page}, 'btn', 'btn-light', 'border-secondary']" @click="loadMoreBooks(p)">{{ p }}</button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-3 book-categories mt-4">
                                <div class="pl-2 pb-2"><strong >Danh mục </strong></div>
                                <ul>
                                    <li class="list-item-in-category" v-for="cate in categories" :key="cate.id"><a :href="'?cate_id='+cate.id" @click="submitCategory(cate)">{{cate.name}}</a></li>
                                </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BookCard from '@/components/book/BookCard.vue';
import { categoryService, bookService } from '@/services';
import Dropdown from 'vue-simple-search-dropdown';
export default {
    name:"Books",
    components:{BookCard,Dropdown},
    data(){
        return{
            totalBooks:[],
            categories:[],
            page: 1,
            authors:[],
            selectedAuthor:'',
            selectedCategory:'',
            emptyMessage:null,
            searchString:null,
        }
    },
    mounted(){
        categoryService.getCategoryByType('BOOK').then(res=>{
            this.categories = res;
            if(this.$route.query.cate_id && !isNaN(this.$route.query.cate_id)){
                bookService.getBooksInCategory(this.$route.query.cate_id, 1, 6).then(res => {
                    this.totalBooks = res;

                    if (this.totalBooks.total == 0) {
                        this.emptyMessage = 'No books found!'
                    }
                })
            }else{
                bookService.getBooksRecommended(1, 6).then(res => {
                    this.totalBooks = res;

                    if (this.totalBooks.total == 0) {
                        this.emptyMessage = 'No books found!'
                    }
                })
            }
            
        })
        bookService.getBookAuthors().then(res=>{
            this.authors = res;
        })
    },
    methods: {
       loadMoreBooks(index){
            this.page = index++;
            if (this.$route.query.cate_id && !isNaN(this.$route.query.cate_id)) {
                bookService.getBooksInCategory(this.$route.query.cate_id, this.page, 6).then(res => {
                    this.totalBooks = res;

                    if (this.totalBooks.total == 0) {
                        this.emptyMessage = 'No books found!'
                    }
                })
            }
            else{
                bookService.getBooksRecommended(this.page, 6).then(res => {
                    this.totalBooks = res;

                    if (this.totalBooks.total == 0) {
                        this.emptyMessage = 'No books found!'
                    }
                }, err => console.log(err))
            }
        },
        getAuthorValue(e){
            return e;
        },
        getAuthorSelected(author){
            this.selectedAuthor = author.name;
        },
        submitFilter(){
            let author = (this.selectedAuthor != '') ? {author:this.selectedAuthor} : null;
            let filterContent = {...author};
            bookService.filterBooks(filterContent).then(res=>{
                this.totalBooks.data = res;
                this.totalBooks.total = 0;
                if(res.length == 0){
                    this.emptyMessage = 'Không có dữ liệu'
                }
            });
        },
        submitCategory(cate){
            bookService.getBooksRelated(cate.id).then(res=>{
                this.totalBooks.data = res;
                this.totalBooks.total = 0;
                if(res.length == 0){
                    this.emptyMessage = 'Không có dữ liệu'
                }
                else{
                    this.emptyMessage = null;
                }
            });
        },
        search(){
            bookService.searchBooks(this.searchString).then(res=>{
                this.totalBooks.data = res;
                this.totalBooks.total = 0;
                // if(res.length == 0){
                //     this.emptyMessage = `Không có dữ liệu cho '${this.searchString}'`;
                // }
            },err=>{console.log(err)})
        }
    }
}
</script>

<style scoped>
.layout_padding{
  padding: 30px 0!important;
}
.btn-loadmore{
    text-decoration: underline;
    color:black !important;
    margin: 1em auto;
}
.dropdown{
    margin:0px 12px
}
.author-filter, .category-filter{
    align-items:center;
}
.filter-title > span{
    align-self: center;
}
.courses-slide > .box{
    width: 14rem;
}
.btn-filter{
    background-color: whitesmoke !important;
    color: black;
    border: 1px solid gray
}
.empty-message{
    margin:0 auto;
}
.book-categories ul > li > a{
    color: black !important;
}
</style>