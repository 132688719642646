import config from '../config/api.js';
import { authHeader} from '../helpers';

export const orderService = {
    checkCurrentUserOrder,
};

function checkCurrentUserOrder(productId, productType) {
    const params = {productId:productId, productType:productType}
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    }
    return fetch(`${config.apiUrl}/order/checkCurrentUserOrder`, requestOptions)
    .then(handleResponse);
}

function logout() {
    // xoá user từ local storage để log out
    localStorage.removeItem('user');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // tự động logout nếu response 401 được trả về từ api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data.data;
    });
}