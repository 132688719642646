<template>
  <div class="about-us">
    <Header></Header>
    <AboutUs></AboutUs>
    <Footer></Footer>
  </div>
</template>

<script>
import AboutUs from '@/components/layouts/Aboutus.vue';
import Header from '@/components/layouts/Header.vue';
import Footer from '@/components/layouts/Footer.vue';

document.title = "Thần thoại quanh ta - Về chúng tôi";
export default {
  components: { AboutUs, Header, Footer},
  name: 'AboutUsView',
  data: () => ({
    //
  }),
};
</script>

<style scoped>
.about-us{
  background-color: #fcfaf7;
}
</style>