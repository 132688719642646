<template>
    <div class="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'app',
};
</script>

<style scoped>
/* Default style */
@import './assets/css/bootstrap.css';
@import './assets/css/font-awesome.min.css';
@import './assets/css/responsive.css';

/* Font Style */
@import 'https://fonts.googleapis.com/css?family=Poppins:400,600,700&display=swap';

/* Custom style */
@import './assets/css/style.css';

</style>