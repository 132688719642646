<template>
    <div class="login-page">
        <LoginForm></LoginForm>
    </div>
</template>

<script>
import LoginForm from '@/components/auth/LoginForm.vue';
document.title = "Thần thoại quanh ta - Đăng nhập ";
export default {
    name:"Login",
    components:{ LoginForm }
}
</script>

<style scoped>
.login-page{
    background-image: url('https://t4.ftcdn.net/jpg/03/41/83/83/360_F_341838327_s6a1cXcFzdCXydsGXeUZnUlbKycoI8y1.jpg');
    background-repeat: no-repeat; 
    background-size: cover;
}
</style>