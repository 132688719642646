<template>
    <div :class="className">
        <div class="box">
            <a :href="'/story/'+book.slug">
            <div class="img-box">
                <img class="thumbnail-book" :src="book.thumbnail_url" :alt="book.title" :height="imageHeight">
            </div>
            <!-- <div class="mt-2 mr-3 read-count d-flex float-right">
                <i class="fa fa-book mt-1 mr-2"></i>
                <h6>{{ book.read_count }}</h6>
            </div> -->
            <div class="detail-box">
                <div class="title-author">
                    <h5>
                        {{ book.title }}
                    </h5>
                    <p class="short-description pt-2">
                        {{ book.description}}
                    </p>
                </div>
                <div class="price_box d-flex justify-content-between pt-2">
                    <h6 class="price_heading">
                    <span><i class="fa fa-shopping-cart mr-2" aria-hidden="true"></i></span> {{ book.order_count}}
                    </h6>
                    <a class="btn btn-warning" :href="'/story/'+book.slug" style="border: none; background-color: #E7EEB5; color: #082E49 ">
                        Xem thêm
                    </a>
                </div>
            </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name:"BookCard",
    props:['book','className'],
    mounted(){
        this.originImageWidth = document.querySelector("img").naturalWidth;
        if(window.innerWidth >= 993 ){
            this.imageHeight = (1/5)*this.originImageWidth
        }
        else if(window.innerWidth > 767 && window.innerWidth < 993){
            this.imageHeight = (1.3/5)*this.originImageWidth
        }
        else if(window.innerWidth <= 767 && window.innerWidth > 410){
            this.imageHeight = (1.9/5)*this.originImageWidth
        }
        else{
            this.imageHeight = (1.6/5)*this.originImageWidth
        }
    },
    data(){
        return{
            imageHeight:'auto',
            originImageWidth: '100%',
        }
    }
}
</script>

<style scoped>

.img-box{
    padding:0 !important;
    align-items: flex-start !important;
    height:auto !important;
}
.thumbnail-book{
    border-radius: 3% 3% 0 0;
    width:100%;
}
.box{
    border-radius:3%;
    padding:0 !important;
    margin-top: 30px !important;
    border: 0.5px solid lightgrey;
    height:auto;
}
.box a{
    color: black;
}
.title-author .short-description{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 0.8rem;
    font-weight: 600;
    height: 65px;
}
.title-author > h5{
    height:3rem; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.detail-box{
    padding:15px;
}

.price_box > h6 > span {
    color:black !important;
}
</style>