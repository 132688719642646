<template>
    <div class="courses">
        <section class="furniture_section layout_padding">
            <div class="container">
                <div class="heading_container">
                    <p class="title-home">
                        Sản phẩm đề xuất
                    </p>
                    <div class="description d-inline-block w-100" >
                        <span class="float-right">
                            <a class="see-more-link" href="/courses">Xem thêm <i class="ml-1 fa fa-chevron-right"></i></a>
                        </span>
                    </div>
                </div>
                <div class="courses-slide">
                    <div class="row">
                        <CourseCard v-for="course in courses" :key="course.id" :course="course" :className="'col-md-6 col-lg-3'"></CourseCard>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { courseService } from '@/services';
import CourseCard from '@/components/course/CourseCard.vue';

export default {
    name:"CourseArea",
    components:{
        CourseCard
    },
    data(){
        return{
            courses:[]
        }
    },
    mounted(){
        courseService.getCoursesRecommended().then(res=>{
            this.courses = res.data;
        });
    },
}
</script>

<style scoped>
.see-more-link{
    text-decoration: underline;
    color: black;
}
.layout_padding{
    padding: 30px 0 !important;
}
</style>