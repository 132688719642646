<template>
    <div class="book-detail mt-2 container mb-5">
        <!-- Book thumbnail -->
        <div class="book-section row">
            <div class="col-lg-5">
                <div class="book-thumbnail">
                    <img :src="book.thumbnail_url" width="100%" :alt="book.description" />
                </div>
            </div>
            <div class="col-lg-7">
                <div class="book-description">
                    <div class="author d-flex">
                        <div class="author-avatar circle shadow border">
                            <img class="initials" src="" alt="">
                        </div>
                        <div class="ml-3 author-name">
                            {{ book.author }}
                        </div>
                    </div>
                    <p>{{ book.description }}</p>
                    <div class="price text-center">
                        <span><b>{{ book.price | formatCurrency}}</b> {{ book.unit }}</span>
                    </div>
                    <div class="book-short-info mt-2 d-flex justify-content-around">

                        <audio-player v-if="!status.loggedIn || !isFullAudio"
                            ref="audioPlayer"
                            :audio-list="audiosPreview"
                            type="audio/mpeg"
                            theme-color="#ff2929"
                        />
                        <audio-player v-if="status.loggedIn && isFullAudio"
                            ref="audioPlayer"
                            type="audio/mpeg"
                            :audio-list="audiosFull"
                            theme-color="#ff2929"
                        />
                        <button v-else class="btn btn-warning" @click="show">Mua Audio đầy đủ</button>
                    </div>
                </div>
                <div class="title-book">
                    <h4><strong>{{ book.title}}</strong></h4>
                </div>
            </div>
        </div>

        <!-- Book Content -->
        <div class="book-content row mt-2 p-3" v-html="book.content">
        </div>
       
        <!-- Book order -->
        <modal name="order-book" height="400px" :adaptive="true">
            <div class="container m-2">
                <div class="header-modal d-flex">
                    <div class="title w-100 text-center mr-auto">
                        <h4>Mua Audio</h4>
                    </div>
                    <div class="close-button">
                        <button class="btn" @click="hide">&#10006;</button>
                    </div>
                </div>
                <div class="form-group book-name mt-4">
                    <h5>{{ book.title }}</h5>
                </div>

                <div class="form-group author mt-2">
                    Tác giả: {{ book.author }}
                </div>
                <div class="form-group price mt-2">
                    Giá:  <strong>{{ book.price | formatCurrency }} {{ book.unit}}</strong>
                </div>

                <div class="form-group mt-4">
                    <input type="text" class="form-control" id="phone" v-model="phone" placeholder="Phone Contact"/>
                </div>

                <div class="form-group mt-2">
                    <textarea type="text" class="form-control" id="note" v-model="note" placeholder="Note"></textarea>
                </div>
                

                <div class="text-danger">{{ error ? error : ''}}</div>
                <div class="btn-submit text-center">
                    <button type="submit" class="btn btn-primary" @click="orderBook">Gửi</button>
                </div>
            </div>
        </modal>

        <div class="course-comment mt-3">
            <Comments v-if="book.id != null" :postId="book.id" postType='BOOK'></Comments>
        </div>
        
        <div class="book-related mt-3">
            <h4>Stories liên quan</h4>
            <swiper class="swiper" :options="swiperOption">
                <swiper-slide class="col-lg-3 col-md-6 col-sm-6" v-for="item in books" :key="item.id" ><BookCard :book="item" :className="''"></BookCard></swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
        </div>
    </div>    
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {bookService, orderService} from '@/services';
import { mapState } from 'vuex'

import Comments from '@/components/comment/CommentArea.vue';
import BookCard from '@/components/book/BookCard.vue';

import 'swiper/css/swiper.css'
var screenWidth = screen.width;
import AudioPlayer from '@liripeng/vue-audio-player'

export default {
    name:"Book",
    components:{Comments, BookCard, Swiper,SwiperSlide, 
    AudioPlayer
    },
    data(){
        return{
            audiosPreview:[],
            audiosFull:[],
            book: new Object(),
            isFullAudio:false,
            books:[],
            images:[],
            quantity:0,
            phone:null,
            note:null,
            error:null,
            swiperOption: {
                slidesPerView: screenWidth >= 990 ? 2 : 1,
                spaceBetween: 0,
                centeredSlides: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        }
    },
    created(){
        bookService.getBookDetail(this.$route.params.slug).then(res=>{
            this.book = res;
            this.audiosPreview.push(res.preview_audio);
            this.audiosFull.push(res.full_audio);
            bookService.getBooksRelated(this.book.category_id).then(res=>{
                this.books = res;
            });
            let params = {
                        'productId': this.book.id,
                        'productType': 'BOOK',
                        'sort': 1,
                    };
            bookService.getBookFiles(params).then(res=>{
                this.images = res;
            })
            if(this.status.loggedIn){
                orderService.checkCurrentUserOrder(this.book.id, 'BOOK').then(res=>{
                    if(res == null){
                        return;
                    }
                    else{
                        if(res.is_paid == 1){
                            this.isFullAudio = true;
                        }
                        else{
                            this.warning = "You haven't paid the course yet, please complete payment!"
                        }
                    }

                })
            }
        });
        
    },
    computed:{
        ...mapState('account', ['status'])
    },
    methods:{
        show () {
            if(!this.status.loggedIn){
                this.$router.push('/login');
            }
            this.$modal.show('order-book');
        },
        hide () {
            this.$modal.hide('order-book');
        },
        inited (viewer) {
            this.$viewer = viewer
        },
        showImage () {
            this.$viewer.show()
        },
        orderBook(){
            let params = {
                'bookId': this.book.id,
                'bookName': this.book.title,
                'amount' : this.book.price,
                'quantity': parseInt(this.quantity),
                'note'   : this.note
            }
            bookService.orderBook(params).then((res,err)=>{
                if(res.id){
                    this.$modal.hide('order-book'); 
                }
                else{
                    this.error = err;
                }
            })
        },
    }
}
</script>

<style scoped>
@media (max-width: 576px){
    .swiper-wrapper > div{
        margin-right: 0 !important;
        width: 13rem!important;
    }
}
/* @media (min-width: 992px){
.swiper-wrapper > .slider-slide{
        margin-right: 0 !important;
}
} */
.book-description{
    border: 1px solid lightgrey;
    border-radius: 1rem;
    padding:1rem;
    min-height: 20rem;
}
.book-short-info > div:first-child{
    border-color: lightgrey;
}
.book-thumbnail > img{
    min-height: 20rem;
}
.circle {
  border-radius: 50%;
  height: 2.7rem;
  text-align: center;
  width: 2.7rem;
  background-color: #ccc;
  position: relative;
}

.initials {
  font-size: calc(2.5rem / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(2.5rem / 5); /* 25% of parent */
}
.author .author-name{
    margin:auto 0;
}
.author{
    margin-bottom: 1rem;
}
.title-book{
    text-align: center;
    margin-top: 1rem;
}
.enroll-button > button{
    margin:auto 0;
    min-height: 3rem;
}
</style>
