<template>
    <div class="reviews" v-if="totalComments.length > 0">
        <section class="furniture_section layout_padding">
            <div class="container">
            <div class="heading_container">
                <p class="title-home">
                    Cảm nhận
                </p>
            </div>
            <div class="reviews-slide">
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide v-for="comment in totalComments" :key="comment.id" class="slide">
                        <div class="comment-content d-flex">
                            <div class="col-lg-4 text-center m-auto">
                                {{ comment.post_type}}
                            </div>
                            <div class="flex-grow-1 ">
                                <q class="">
                                    <i>{{ comment.content}}</i>
                                </q>
                                <p class="text-left mt-5">
                                    <b>{{ comment.user_name}}</b>
                                </p>
                            </div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                    <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                    <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                </swiper>
            </div>
            </div>
        </section>
    </div>
</template>

<script>
import {commentService} from '@/services';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name:"CommentsHome",
    components:{Swiper,SwiperSlide},
    data(){
        return{
            totalComments:[],
            swiperOption: {
                spaceBetween: 30,
                centeredSlides: true,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        }
    },
    mounted(){
        commentService.getCommentsRecommend(5).then(res=>{
            this.totalComments = res;
            console.log(this.totalComments);
        })
    },
    methods: {
    }
    
}
</script>

<style scoped>
.comment-content{
    background-color: rgb(240, 183, 59);
    border-radius: 1rem;
    padding:2rem;
}

</style>