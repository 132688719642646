<template>
    <div id="login-form" class="container">
        <h2 class="text-center pt-5">Đăng nhập</h2>
        <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="email"><strong>Email Or Phone</strong></label>
                <input type="text" v-model="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && !email }" />
                <div v-show="submitted && !email" class="invalid-feedback">Email is required</div>
            </div>
            <div class="form-group">
                <label htmlFor="password"><strong>Password</strong></label>
                <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
                <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
            </div>
            <div class="form-group">
                <button class="btn btn-primary" :disabled="status.loggingIn">Đăng nhập</button>
                <img v-show="status.loggingIn" />
                <router-link to="/register" class="btn btn-link"><button class="btn btn-light">Đăng ký</button></router-link>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    name:"Login",
    data () {
        return {
            email: '',
            password: '',
            submitted: false
        }
    },
    computed: {
        ...mapState('account', ['status'])
    },
    mounted(){
        var container = document.getElementById('login-form');
        container.style.minHeight = screen.height+'px';
    },
    methods: {
        ...mapActions('account',['login']),
        handleSubmit () {
            this.submitted = true;
            const { email, password } = this;
            if (email && password) {
                this.login({email, password});
            }
        }
    }
};
</script>

<style scoped>
.container{
    color: white !important;
}
</style>