<template>
  <div class="banners">
    <swiper class="swiper" :options="swiperOption" >
        <swiper-slide v-for="banner in banners" :key="banner.id" class="slide"><a class="btn-see-more" :href="banner.link" :alt="banner.description" target="_blank"><img :src="banner.url" class="img-banner"/></a></swiper-slide>
        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
      </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { bannerService } from '@/services';
export default {
  name: 'Banner',
  components: {Swiper,SwiperSlide},
  data () {
      return{
        banners:[],
        swiperOption: {
          spaceBetween: 30,
          centeredSlides: true,
          autoHeight: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
        }
      }
  },
  mounted(){
    bannerService.getBannersByType("HOME").then(res=>{
      this.banners = res;
    });
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.banners{
  width: 100vw !important;
}
.img-banner {
    width: 1280px;
    height: 628px;
    margin: auto;
    display: block;
}

</style>
