<template>
  <div class="courses-page">
    <Header></Header>
    <CourseArea></CourseArea>
    <Footer></Footer>
  </div>
</template>

<script>
import CourseArea from '@/components/course/Courses.vue';
import Header from '@/components/layouts/Header.vue';
import Footer from '@/components/layouts/Footer.vue';

document.title = "Thần thoại quanh ta - Sản phẩm";
export default {
  components: { CourseArea, Header, Footer},
  name: 'Courses',
  data: () => ({
    //
  }),
};
</script>

<style scoped>
.courses-page{
  background-color: #fcfaf7;
}
</style>