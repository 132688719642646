<template>
    <div class="comment-part" >
        <h4>Review</h4>
        <div class="post-comment d-flex mt-4" v-if="status.loggedIn">
            <div class="user-avatar col-2 align-self-center">
                <div class="circle border shadow">
                    <span class="initials current-user">Bạn</span>   
                </div>
            </div>
            <div class="content col-8 form-group">
                <textarea v-model="content" class="input-content form-control" placeholder="Comment goes here!" cols="90"></textarea>
            </div>
            <div class="submit-comment col-2 align-self-center">
                <button class="btn btn-light border border-secondary" @click="submitComment">Gửi</button>
            </div>
        </div>
        <div class="comments" v-if="commentsCount > 0">
            <h6>Tổng cộng: {{ commentsCount}}</h6>
            <div class="comments">
                <Comment v-for="comment in totalComments" :key="comment.id" :comment="comment"></Comment>
            </div>
        </div>
        
    </div>
</template>

<script>
import Comment from '@/components/comment/Comment.vue';
import {commentService} from '@/services';
import { mapState } from 'vuex';
export default {
    name:"Comments",
    props: ['postId','postType'],
    components:{Comment},
    data(){
        return{
            totalComments:[],
            page:1,
            isLoadMore: true,
            commentsCount:0,
            content:null
        }
    },
    computed: {
        ...mapState('account', ['status','user'])
    },
    mounted(){
        commentService.getCommentsByPost(1, this.postId, this.postType).then(res=>{
            this.totalComments = res.data;
            this.commentsCount = res.total;
            if(res.per_page >= res.total){
                this.isLoadMore = false;
            }
        })
    },
    methods: {
        loadMore(){
            this.page +=1;
            commentService.getCommentsByPost(this.page, this.postId, this.postType).then(res=>{
                this.totalComments = this.totalComments.concat(res.data);
                if(res.current_page >= res.last_page){
                    this.isLoadMore = false;
                }
            })
        },
        formatName(name){
            let fullname = name.split(' ')
            let initials = fullname.shift().charAt(0) + fullname.pop().charAt(0);
            return initials.toUpperCase();
        },
        submitComment(){
            let params = {
                postId: this.postId,
                postType: this.postType,
                content: this.content
            }
            commentService.postComment(params).then(res=>{
                this.totalComments.unshift(res);
                this.commentsCount = this.totalComments.length
            }, err=> console.log(err))
        }
    }
    
}
</script>

<style scoped>
.comments{
    margin-bottom: 2rem;
    margin-top: 2rem;
}
.circle {
  border-radius: 50%;
  height: 3.0rem;
  text-align: center;
  width: 3.0rem;
  background-color: #ccc;
  position: relative;
}

.initials {
  font-size: calc(2.5rem / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(3.9rem / 5); /* 25% of parent */
}
.post-comment .user-avatar .circle{
    background-color: #fccf03 !important;
}
.content .input-content{
    border-radius: 10px;
    padding:0.5rem;
}
.content{
    margin: auto 0;
}
.submit-comment > button{
    font-size:0.8rem;
}
</style>