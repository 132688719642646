<template>
  <div class="book-detail">
    <Header></Header>
    <Book></Book>
    <Footer></Footer>
  </div>
</template>

<script>
import Book from '@/components/book/Book.vue';
import Header from '@/components/layouts/Header.vue';
import Footer from '@/components/layouts/Footer.vue';

document.title = "Thần thoại quanh ta - Kho truyện";
export default {
  components: { Book, Header, Footer},
  name: 'BookDetail',
  data: () => ({
    //
  }),
};
</script>

<style scoped>
.book-detail {
  background-color: #fcfaf7;
}
</style>