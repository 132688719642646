import { authHeader} from '../helpers';
import config from '../config/api.js';

export const courseService = {
    getCoursesRecommended,
    getCoursesJoined,
    getCoursesMostJoined,
    getCourseAuthors,
    filterCourses,
    getCourseDetail,
    getCoursesRelated,
    registerCourse,
    getVideosInCourse,
    checkCurrentUserJoinedCourse,
    searchCourses
};

function getCoursesRecommended(page = 1) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ courseNum:4 })
    };
    return fetch(`${config.apiUrl}/course/coursesRecommended?page=${page}`, requestOptions)
        .then(handleResponse);
}

function getCoursesJoined(page = 1) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ courseNum:4 })
    };

    return fetch(`${config.apiUrl}/course/coursesJoined?page=${page}`, requestOptions)
        .then(handleResponse);
}

function getCoursesMostJoined(page = 1){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ courseNum:4 })
    };

    return fetch(`${config.apiUrl}/course/coursesMostJoined?page=${page}`, requestOptions)
        .then(handleResponse);
}

function getCourseAuthors(){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/course/getAuthors`, requestOptions)
        .then(handleResponse);
}


function getCourseDetail(slug){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/course/detail/${slug}`, requestOptions)
        .then(handleResponse);
}


function filterCourses(filterContent){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const queryStr = Object.keys(filterContent)
    .map(key => `${key}=${filterContent[key]}`)
    .join('&');

    return fetch(`${config.apiUrl}/course/filterCourses?${queryStr}`, requestOptions)
        .then(handleResponse);
}

function getCoursesRelated(categoryId){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/course/coursesRelated/${categoryId}`, requestOptions)
        .then(handleResponse);
}

function registerCourse(params){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params),
    }
    return fetch(`${config.apiUrl}/course/registerCourse`, requestOptions)
        .then(handleResponse);
}

function getVideosInCourse(params){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params),
    }
    return fetch(`${config.apiUrl}/document/getDocumentsByProduct`, requestOptions)
    .then(handleResponse);
}

function checkCurrentUserJoinedCourse(courseId){
    const params = {productId:courseId, productType:'COURSE'}
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    }
    return fetch(`${config.apiUrl}/order/checkCurrentUserOrder`, requestOptions)
    .then(handleResponse);
}

function searchCourses(searchString){
    const requestOptions ={
        method: 'GET',
        headers:  { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.apiUrl}/course/search/${searchString}`, requestOptions)
    .then(handleResponse);
}

function logout() {
    // xoá user từ local storage để log out
    localStorage.removeItem('user');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // tự động logout nếu response 401 được trả về từ api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data.data;
    });
}