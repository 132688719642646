<template>
    <div class="blog-detail-component">
        <div class="container">
            <div class="blog-content">
                <div class="single-post-details-area">
                    <div class="post-content">
                        <div class="text-center mb-50">
                            <p class="post-date"></p>
                            <h2 class="post-title">{{ blog.title}}</h2>
                            <!-- Post Meta -->
                            <div class="post-meta d-flex justify-content-around mb-4">
                                <span>Bởi <i>{{ blog.author}}</i></span>
                                <span> {{ blog.views_count}} Lượt xem</span>
                            </div>
                        </div>

                        <!-- Post Thumbnail -->
                        <div class="post-thumbnail mb-5 text-center">
                            <img width="100%" :src="blog.thumbnail_url" :alt="blog.title" />
                        </div>
                        
                        <p class="post-excerpt">{{ blog.brief }}</p>

                        <div class="content" v-html="blog.content">
                        </div>
                    </div>

                </div>
            </div>

            <!-- Comment Area -->
            <div class="comment-area mt-4">
                <CommentArea v-if="blog != null" :postId="blog.id" postType="BLOG"></CommentArea>
            </div>

            <!-- Related Post Area -->
            <div class="book-related mt-3">
                <h4>Blogs liên quan</h4>
                <swiper class="swiper" :options="swiperOption" v-if="blogs.length > 0" >
                    <swiper-slide class="col-lg-3 col-sm-6 col-md-6" v-for="item in blogs" :key="item.id"><BlogCard :blog="item" :className="''"></BlogCard></swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import CommentArea from '@/components/comment/CommentArea.vue';
import BlogCard from '@/components/blog/BlogCard.vue';
import {blogService} from '@/services';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
var screenWidth = screen.width;
export default {
    name:"BlogDetail",
    components:{CommentArea,BlogCard, Swiper, SwiperSlide},
    data(){
        return{
            blog:null,
            blogs:[],
            swiperOption: {
                slidesPerView: screenWidth >= 990 ? 2: 1,
                spaceBetween: 10,
                centeredSlides: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        }
    },
    mounted(){
        let slug = this.$route.params.slug;
        blogService.getBlogDetailBySlug(slug).then(res=>{
            this.blog = res;
            document.title = 'Thần thoại quanh ta - '+res.title;
            blogService.getBlogsInCategory(res.category_id).then(res=>{
                this.blogs = res.data
            },error=>console.log(error))
        });

    }   
}
</script>

<style scoped>
@media (max-width: 576px){
    .swiper-wrapper > div{
        margin-right: 0 !important;
        width: 15.5rem!important;
    }
}
</style>