<template>
    <div id="register-form" class="container pt-5">
        <h2 class="text-center">Đăng ký</h2>
        <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="firstName"><strong>Họ</strong></label>
                <input type="text" v-model="user.fname" required name="firstName" class="form-control" :class="{ 'is-invalid': submitted && errors.has('firstName') }" />
            </div>
            <div class="form-group">
                <label for="lastName"><strong>Tên</strong></label>
                <input type="text" v-model="user.lname" required name="lastName" class="form-control" :class="{ 'is-invalid': submitted && errors.has('lastName') }" />
            </div>
            <div class="form-group">
                <label for="email"><strong>Email</strong></label>
                <input type="email" v-model="user.email" required name="email" class="form-control" :class="{ 'is-invalid': submitted && errors.has('email') }" />
            </div>
             <div class="form-group">
                <label for="email"><strong>Phone</strong></label>
                <input type="text" v-model="user.phone" required name="phone" class="form-control" :class="{ 'is-invalid': submitted && errors.has('phone') }" />
            </div>
            <div class="form-group">
                <label htmlFor="password"><strong>Password</strong></label>
                <input type="password" v-model="user.password" required min=8 name="password" class="form-control" :class="{ 'is-invalid': submitted && errors.has('password') }" />
            </div>
            <div class="form-group text-center">
                <button class="btn btn-primary" :disabled="status.registering">Đăng ký</button>
                <img v-show="status.registering" />
                <router-link to="/login" class="btn btn-link"><button class="btn btn-light">Cancel</button></router-link>
            </div>
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name:"RegisterForm",
    data () {
        return {
            user: {
                fname: '',
                lname: '',
                email: '',
                password: '',
                phone:''
            },
            submitted: false
        }
    },
    mounted(){
        var container = document.getElementById('register-form');
        container.style.minHeight = screen.height+'px';
    },
    computed: {
        ...mapState('account',['status'])
    },
    methods: {
        ...mapActions('account',['register']),
        handleSubmit(e) {
            console.log(e);
            this.submitted = true;
            this.register(this.user);
        }
    }
};
</script>

<style scoped>
.container {
    color: white !important;
}
</style>