<template>
  <div class="books-page">
    <Header></Header>
    <BooksArea></BooksArea>
    <Footer></Footer>
  </div>
</template>

<script>
import BooksArea from '@/components/book/Books.vue';
import Header from '@/components/layouts/Header.vue';
import Footer from '@/components/layouts/Footer.vue';

document.title = "Thần thoại quanh ta - Kho truyện";
export default {
  components: { BooksArea, Header, Footer},
  name: 'Books',
  data: () => ({
    //
  }),
};
</script>

<style scoped>
.books-page {
  background-color: #fcfaf7;
}
</style>