<template>
    <div class="footer">
        <!-- <section class="info_section long_section">
            <div class="container">
            <div class="contact_nav">
                <a href="">
                <i class="fa fa-phone" aria-hidden="true"></i>
                <span>
                    Call : +01 123455678990
                </span>
                </a>
                <a href="">
                <i class="fa fa-envelope" aria-hidden="true"></i>
                <span>
                    Email : demo@gmail.com
                </span>
                </a>
                <a href="">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <span>
                    Location
                </span>
                </a>
            </div>

            <div class="info_top ">
                <div class="row ">
                <div class="col-sm-6 col-md-4 col-lg-3">
                    <div class="info_links">
                    <h4>
                        QUICK LINKS
                    </h4>
                    <div class="info_links_menu">
                        <a class="" href="index.html">Home <span class="sr-only">(current)</span></a>
                        <a class="" href="about.html"> About</a>
                        <a class="" href="furniture.html">Furniture</a>
                        <a class="" href="blog.html">Blog</a>
                        <a class="" href="contact.html">Contact Us</a>
                    </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3 mx-auto">
                    <div class="info_post">
                    <h5>
                        INSTAGRAM FEEDS
                    </h5>
                    <div class="post_box">
                        <div class="img-box">
                        <img src="images/f1.png" alt="">
                        </div>
                        <div class="img-box">
                        <img src="images/f2.png" alt="">
                        </div>
                        <div class="img-box">
                        <img src="images/f3.png" alt="">
                        </div>
                        <div class="img-box">
                        <img src="images/f4.png" alt="">
                        </div>
                        <div class="img-box">
                        <img src="images/f5.png" alt="">
                        </div>
                        <div class="img-box">
                        <img src="images/f6.png" alt="">
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="info_form">
                    <h4>
                        SIGN UP TO OUR NEWSLETTER
                    </h4>
                    <form action="">
                        <input type="text" placeholder="Enter Your Email">
                        <button type="submit">
                        Subscribe
                        </button>
                    </form>
                    <div class="social_box">
                        <a href="">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                        <a href="">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                        </a>
                        <a href="">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </a>
                        <a href="">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section> -->

        <!--<footer class="footer_section">
            <div class="container">
            <p>
                © <span id="displayYear">2022</span> All Rights Reserved By
                Nguyen Tien Khang
            </p>
            </div>
        </footer>-->
    </div>
</template>

<script>
export default {
    name:"Footer"
}
</script>

<style scoped>
.footer{
    border-top: 1px solid lightgrey;
    width: 100%;
    position: static;
    bottom: 0;
}
</style>