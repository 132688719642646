import { authHeader} from '../helpers';
import config from '../config/api.js';

export const commentService = {
    getCommentsByPost,
    postComment,
    getCommentsRecommend
    
};

function getCommentsByPost(page = 1, postID, postType) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({commentNum: 10, postId: postID, type: postType })
    };
    return fetch(`${config.apiUrl}/comment/getComments?page=${page}`, requestOptions)
        .then(handleResponse);
}

function getCommentsRecommend(number = 5){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({commentNum:number})//commentNum is number of comment show in home
    }
    return fetch(`${config.apiUrl}/comment/getCommentsHome`, requestOptions)
    .then(handleResponse);
}

function postComment(params) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(params)
    };
    return fetch(`${config.apiUrl}/comment/postComment`, requestOptions)
        .then(handleResponse);
}

function logout() {
    // xoá user từ local storage để log out
    localStorage.removeItem('user');
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // tự động logout nếu response 401 được trả về từ api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data.data;
    });
}